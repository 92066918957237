/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import axios from 'axios'
import AsyncSelect from 'react-select/async'
import { useDispatch, useSelector } from "react-redux"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch, faArrowCircleRight } from "@fortawesome/free-solid-svg-icons"

import { clearLoading } from "./../actions"

import Layout from "../components/layout"
import LeafletMap from "../components/leafletmap"
import SEO from "../components/seo"
import Login from "../components/login"

import venteDirecte1 from '../images/vente-directe1.svg'
import pinMap from '../images/icon/pin-map.svg'

export const IndexPage = () => {

  const data = useStaticQuery(graphql`
    query homeImages {
      bg1: file(relativePath: { eq: "bg-home.jpg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      allMysqlZone {
        nodes {
          zone_libelle
          zone_id
        }
      }
    }
  `)

  const identifiants = useSelector(s => s.identifiants)
  const [positionZoom, setPositionZoom] = useState("")
  const [producteur, setProducteur] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearLoading(true, ""))
  }, [dispatch])

  const handleInputChange = event => {
    if (event !== null) {
      setPositionZoom({
        latlng: {
          lat: event.value[1],
          lng: event.value[0]
        },
        zoom: 12,
      })
    }
  }

  const onZoneChange = event => {
    if (parseInt(event.target.value) === 0) {
      setProducteur()
    } else if (parseInt(event.target.value) === 2) {
      setProducteur([2, 11])
    } else {
      setProducteur([parseInt(event.target.value)])
    }
  }

  const loadOptions = (inputValue, callback) => {
    const API_URL = 'https://api-adresse.data.gouv.fr/search/'
    axios.get(`${API_URL}?q=${inputValue}&type=municipality&autocomplete=1`)
      .then(({ data }) => {
        callback(data.features.map(r => ({ value: r.geometry.coordinates, label: r.properties.name, customAbbreviation: r.properties.postcode })))
      })
  }

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: "flex" }}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#ccc" }}>
        {customAbbreviation}
      </div>
    </div>
  )

  return (
    <>
      <Layout>
        <SEO title="Agro Bio Conso" />
        <section className="hero" style={{ marginTop: '-1rem', position: "relative", overflow: "hidden" }}>
          <Img fluid={data.bg1.childImageSharp.fluid} style={{ position: "absolute", top: 0, left: 0, right: 0 }} alt="Agriculture locale" className="is-hidden-mobile is-hidden-tablet-only" />
          <div className="hero-body">
            <div className="container has-text-centered">
              <h1 className="title dreamland is-size-2 mt-5">
                <span style={{ padding: "0.5rem 1rem", background: "#ffffff99" }}>Association de consommateurs de produits</span>
                <br />
                <span style={{ padding: "0.5rem 1rem", background: "#ffffff99", fontSize: '5rem' }}>bio, en circuit court</span></h1>
            </div>
          </div>
        </section>
        <section className="hero steps">
          <div className="hero-body">
            <div className="container is-fluid">
              <div className="columns has-text-centered is-vcentered is-centered">
                <div className="column is-4">
                  <div style={{padding: "1rem 0 1.5rem", background: "#eee", borderRadius: 3}}>
                    <div className="dreamland is-size-1">Soyez consom'acteurs&nbsp;!</div>
                    <div className="is-size-5">
                      <Link to="/association">
                        <FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" /> Comprendre la démarche<br/>d'AgroBioConso
                      </Link>
                    </div>
                  </div>
                  <div style={{marginTop: "2rem", padding: "1rem 0 1.5rem", background: "#eee", borderRadius: 3}}>
                    <div className="almond is-size-3">Astuces et recettes</div>
                    <div className="is-size-5"><a href="https://blog.agrobioconso.org/#content">
                      <FontAwesomeIcon icon={faArrowCircleRight} className="mr-2" /> Suivre le blog</a>
                    </div>
                  </div>
                </div>
                {identifiants.identifiants === null ? (
                  <>
                    <div className="column is-4">
                      <div className="box">
                        <div className="step-num">Nouvel adhérent ?</div>
                        <div className="is-size-5">Je choisis mon point de retrait et mes produits,<br/>puis je m'inscris et valide ma commande</div>
                        <div className="mt-5"><img src={pinMap} style={{ width: 100, marginTop: '0.7rem' }} alt="Click collect" /></div>
                        <a href="/#points-retrait" className="button">Trouver mon point de retrait</a>
                      </div>
                    </div>
                    <div className="column is-4">
                      <div className="box">
                        <div className="step-num">Déjà inscrit ?</div>
                        <Login large={false} />
                      </div>
                    </div>
                  </>
                ) : ""}
              </div>
            </div>
          </div>
        </section>
        <section className="hero pattern-zigzag">
          <div className="hero-body">
            <div className="container has-text-centered">
              <div className="columns is-vcentered" style={{marginLeft: 0, marginRight: 0}}>
                <div className="column is-4">
                  <img src={venteDirecte1} style={{ width: 180 }} alt="Circuit court" />
                </div>
                <div className="column">
                  <div className="title dreamland is-size-2">Un juste prix pour les agriculteurs locaux&nbsp;!</div>
                  <div className="subtitle almond is-size-4">Aucun frais ne grève la relation producteur&nbsp;/&nbsp;consommateur</div>
                  <Link to="/association" className="button dreamland is-size-3">En savoir plus</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section style={{ paddingTop: '3rem', paddingBottom: '3rem' }} id="points-retrait" className="pattern-cross-dots">
          <div className="container is-centered has-text-centered">
            <h2 className="title m-4 dreamland is-size-2">Je choisis mon point de retrait&nbsp;:</h2>
            <div style={{ maxWidth: "49rem", margin: '1.5rem auto 1.5rem' }}>
              <div className="control has-icons-left mr-5" style={{display: "inline-flex", minWidth: "20rem", zIndex: 999}}>
                <AsyncSelect isClearable={true} loadOptions={loadOptions} onChange={e => handleInputChange(e)} formatOptionLabel={formatOptionLabel} noOptionsMessage={() => ''} loadingMessage={() => "Chargement..."} openMenuOnClick={false} placeholder="Rechercher ma commune" className="input is-large mb-4 searchMap" styles={{dropdownIndicator: (provided, state) => ({display: "none"}), indicatorSeparator: (provided, state) => ({display: "none"}), control: (provided, state) => ({...provided, border: 0}), placeholder: (provided, state) => ({...provided, fontSize: "1.2rem"}), valueContainer: (provided, state) => ({...provided, fontSize: "1.2rem"})}} />

                <span className="icon is-large is-left">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </div>
              <span style={{fontWeight: "bold", fontSize: "0.8rem", textDecoration: "underline"}}>Choisir un producteur :</span> <select name="react-tips" className="select is-large" onChange={e => onZoneChange(e)}>
                <option value="0">Tous les producteurs</option>
                {data.allMysqlZone.nodes.filter(zone => parseInt(zone.zone_id) !== 11).map((zone, id) => (
                  <option value={zone.zone_id} key={zone.zone_id}>{zone.zone_libelle}</option>
                ))}
              </select>
            </div>
            {typeof window !== 'undefined' &&
              <div style={{ padding: "0 2rem"}}><LeafletMap positionZoom={positionZoom} accueil={true} producteur={producteur} /></div>
            }
          </div>
        </section>
      </Layout>
    </>
  )
}

export default IndexPage
