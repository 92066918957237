import { login, clearLoading, showLoading, setInfoLivraison, updateInfosPerso, logout } from "./../actions/index"
import { navigate } from "gatsby"
import { logError } from "../services/log"

export const auth = (id, password, redirect = false, nocrypt = false, panier = { products: [], pickupPoint: null, zone: null, }, vldcpt = null) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/LoginAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        password: password,
        nocrypt: nocrypt,
        panier,
        vldcpt
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""));
          alert('Une erreur s\'est produite lors de la récupération de votre profil');
          logError('auth result error', { result, id, password, nocrypt, panier, vldcpt })
        } else {
          dispatch(clearLoading(true, ""))
          dispatch(login(result))
          if (redirect !== false) {
            navigate(redirect)
          }
          if (result.length > 1) {
            navigate(`/apps/mon-compte/choix`)
          }
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""));
        alert('Echec de la récupération de votre profil');
        console.log("Request failed", error)
        logError('auth catch', { error, id, password, nocrypt, panier, vldcpt })
      })
  }
}

export const inscription = (infos, panier = { products: [], pickupPoint: null, zone: null, }) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/CustomerInsert"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        infos,
      }),
    })
      .then(response => {
        return response.json()
      })
      .then(result => {
        if (result.status === "error") {
          dispatch(clearLoading(false))
          logError('inscription result error', { result, infos, panier })
        } else {
          dispatch(clearLoading(true, ""))
          dispatch(auth(result.id, infos.password, "/apps/dashboard/", false, panier))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""));
        console.log("Request failed", error)
        logError('inscription catch', { error, infos, panier })
      })
  }
}

export const updateInfos = (data) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/UpdateInfos"

  return (dispatch, getState) => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: getState().identifiants.identifiants.email,
        hash: getState().identifiants.identifiants.hash,
        data: data,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {

        if (result.status === "error") {
          dispatch(clearLoading(false, result.message))
          //logError('updateInfos result error', {result, data})
        } else {
          dispatch(clearLoading(true, 'Vos modifications ont bien été prises en compte'));
          dispatch(updateInfosPerso(result[0]))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false))
        console.log("Request failed", error)
        logError('updateInfos catch', { error, data })
      })
  }
}

export const updateInfosLivraisons = (LaZone, catIdSelected, customer) => {
  const url =
    process.env.GATSBY_API_URL + "/public/tofront/ListProductInLivrByZone/" + parseInt(LaZone) + "/" + parseInt(catIdSelected)
    + (customer ? ("/" + parseInt(customer)) : "")

  return (dispatch) => {
    dispatch(showLoading())
    fetch(url, {
      method: "GET",
    })
      .then(response => response.json())
      .then(function (result) {
        dispatch(clearLoading(true))
        dispatch(setInfoLivraison(result[0].categorie, result[0].livraison, result[0].produit, LaZone))
      })
      .catch(function (error) {
        dispatch(clearLoading(false))
        console.log("Request failed", error)
        logError('updateInfosLivraisons catch', { error, LaZone, catIdSelected, customer })
      })
  }
}


export const lostPasswordCustomer = (id) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/LostPasswordCustomer"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
          alert('Un problème est survenu lors de l\'envoi de vos identifiants')
          logError('lostPasswordCustomer result error', { result, id })
        } else {
          dispatch(clearLoading(true, ""))
          navigate(`/init-mdp?confirmation=true`)
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        alert('Un problème non identifié est survenu lors de l\'envoi de vos identifiants')
        logError('lostPasswordCustomer catch', { error, id })
      })
  }
}

export const ChangePassWordAPI = (id, requestId, mdp, panier) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/ChangePassWordAPI"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        requestId: requestId,
        password: mdp,
      }),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (result) {
        if (result.status === "error") {
          dispatch(clearLoading(false, ""))
          logError('changePassword result error', { result, id, requestId, panier })
          alert('Echec de validation du nouveau mot de passe. Peut-être avez-vous déjà validé le nouveau mot de passe ?');
        } else {
          dispatch(clearLoading(true, ""))
          dispatch(auth(id, mdp, "/apps/dashboard/", false, panier))
        }
      })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('changePassword catch', { error, id, requestId, panier })
      })
  }
}

export const updatePaiement = (montant, ref) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/updatePaiementAPI"

  fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Mt: montant,
      Ref: ref,
    }),
  })
    .then(function (response) {
      return response.json()
    })
}

export const unsubscribe = (id, password) => {
  var url = process.env.GATSBY_API_URL + "/public/tofront/unsubscribe"
  return dispatch => {
    dispatch(showLoading())
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        password
      }),
    }).then(function (response) {
      return response.json()
    }).then(function (result) {
      if (result.status === "error") {
        dispatch(clearLoading(false, ""))
        logError('unsubscribe result error', { result, id })
        alert('Echec de suppression de vos données personnelles. Veuillez contacter l\'assistance informatique.');
      } else {
        dispatch(clearLoading(true, ""))
        dispatch(logout())
        setTimeout(() => navigate(`/connexion`))
      }
    })
      .catch(function (error) {
        dispatch(clearLoading(false, ""))
        console.log("Request failed", error)
        logError('unsubscribe catch', { error, id })
      })
  }
}