/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import { auth } from "./../actions/auth"
import { clearLoading, showLoading } from "./../actions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons"
import {logError} from "../services/log"

export const Login = props => {
  const loading = useSelector(s => s.loadingR)
  const panier = useSelector(state => state.panierR)
  const dispatch = useDispatch()
  const [form, setForm] = useState({
    username: ``,
    password: ``,
  })
  const isBrowser = typeof window !== "undefined"
  const vldcpt = isBrowser && new URLSearchParams(window.location.search).get("vldcpt") ? new URLSearchParams(window.location.search).get("vldcpt") : null
  const [accounts, setAccounts] = useState([])
  const [vldState, setVldState] = useState(0)

  useEffect(() => {
    if (typeof window !== "undefined" && vldcpt && !vldState) {
      dispatch(showLoading())
      fetch(process.env.GATSBY_API_URL + "/public/tofront/validateEmail", {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ token: vldcpt }),
      }).then(response => response.json()).then(function (result) {
        if (result) {
          dispatch(clearLoading(true, ""));
          setVldState(1);
        } else {
          dispatch(clearLoading(false, ""));
          alert('Votre email n\'a pas pu être validé. Veuillez nous contacter');
          logError('vldcpt error', {result, vldcpt});
        }
      }).catch(function (error) {
        dispatch(clearLoading(false, ""));
        console.log("Request failed", error);
        alert("Une erreur s'est produite pendant la validation de votre email, veuillez nous contacter");
        logError('vldcpt catch', {error, vldcpt});
      })
    }
  }, [vldState, vldcpt, dispatch])

  const handleUpdate = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()

    dispatch(showLoading())
    fetch(process.env.GATSBY_API_URL + "/public/tofront/getLoginsApi", {
      method: "POST",
      headers: { Accept: "application/json", "Content-Type": "application/json" },
      body: JSON.stringify({ search: form.username, password: form.password }),
    }).then(response => response.json()).then(function (result) {
      dispatch(clearLoading(true, ""))
      if (!result.length) {
        dispatch(clearLoading(false, ""))
      } else if (result.length < 2) {
        dispatch(auth(result[0].id, form.password, "/apps/dashboard/", false, panier, vldcpt))
      } else {
        setAccounts(result)
      }
    }).catch(function (error) {
      dispatch(clearLoading(false, ""))
      console.log("Request failed", error)
      alert("Une erreur s'est produite pendant la récupération de vos identifiants, veuillez nous contacter")
      logError('login', {error, username: form.username})
    })
  }

  const selectAccount = event => {
    if (event.target.value) {
      dispatch(auth(event.target.value, form.password, "/apps/dashboard/", false, panier, vldcpt))
    }
  }

  return (
    <>
      {vldState ? <div className="notification is-success is-size-3 almond">Votre email a bien été validé !</div> : null}
      {!accounts.length || accounts.length < 2 ? (
        <form method="post" onSubmit={event => handleSubmit(event)}>
          <div className="field">
            <label className="label is-size-5" htmlFor="email">
              Identifiant ou adresse email ou n° téléphone&nbsp;:
            </label>
            <div className="control">
              <input type="text" id="email" name="username" onChange={handleUpdate} required className={`input ${props.large ? 'is-large' : ''}`} placeholder="Votre identifiant / email / téléphone" style={{ maxWidth: "40rem", textAlign: "center" }} />
            </div>
          </div>
          <div className={`field ${props.large ? 'mt-5' : ''}`}>
            <label className="label is-size-5" htmlFor="password">
              Mot de passe&nbsp;:
            </label>
            <div className="control">
              <input type="password" id="password" name="password" onChange={handleUpdate} required className={`input ${props.large ? 'is-large' : ''}`} placeholder="Votre mot de passe" style={{ maxWidth: "40rem", textAlign: "center" }} />
            </div>
          </div>
          {loading.status === false ? (
            <div className="notification is-danger">L&apos;adresse e-mail ou le mot de passe que vous avez renseigné n&apos;est pas valide</div>
          ) : (
              ""
            )}
          <div className="columns is-vcentered hast-text-centered mt-5">
            <div className="column">
              <Link to="/init-mdp">
                Mot de passe oublié&nbsp;?
              </Link>
            </div>
            <div className="column">
              <button type="submit" className={`button is-primary ${props.large ? 'is-large' : ''} dreamland ${loading.loading ? `is-loading` : ``}`} style={{ fontSize: "2rem", background: "#4d9f16" }}>
                <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
                Connexion
              </button>
            </div>
          </div>
        </form>
      ) : (
          <div className="message is-danger p-5">
            <div className="has-text-weight-bold">Vous avez plusieurs comptes, choisissez celui avec lequel vous souhaitez vous connecter&nbsp;:</div>
            <select className="select mt-3" onChange={e => selectAccount(e)}>
              <option value="">Choisir mon compte adhérent</option>
              {accounts.map(account => <option value={account.id} key={account.id}>{account.login} (rattaché au point de dépôt : {account.group_name})</option>)}
            </select>
          </div>
        )}
    </>
  )
}

export default Login
